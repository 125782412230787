#result-wrapper {
  .result-content {
    border-radius: 8px;
    background-color: $light-gray;
    padding: 60px 120px;
    max-width: 904px;
    margin: 0 auto;
    text-align: center;

    p {
      font-size: 15px;
      color: rgb(98, 109, 113);
      text-transform: uppercase;
      line-height: 1.667;
      margin-bottom: 100px;
    }

    img {
      margin-bottom: 55px;
    }

    .el-button {
      width: 100%;
      max-width: 339px;
    }

    .creditOrDebitUpload {
      display: grid;
      grid-template-columns: 45% 50%;
      column-gap: 5%;
    }
  }
}

@media (max-width: 768px) {
  #result-wrapper .result-content {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  #result-wrapper .result-content {
    padding: 40px 20px;

    p {
      margin-bottom: 20px;
    }

    .creditOrDebitUpload {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
}
